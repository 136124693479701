import { axiosGetRequest } from "../API/API"

export const inputFieldCheck = (
  setLoader,
  setAlreadyExists,
  type,
  value,
  setButtonDisable
) => {
  setLoader(true)
  if (setButtonDisable !== undefined) {
    setButtonDisable(true)
  }
  setTimeout(() => {
    axiosGetRequest(type, value)
      .then(response => {
        if (response.status === 200) {
          setLoader(false)
          setAlreadyExists(response.data.existing)
        }
      })
      .catch(error => {
        console.error(error)
        setLoader(false)
        setAlreadyExists(false)
        if (setButtonDisable !== undefined) {
          setButtonDisable(false)
        }
      })
  }, 1000)
}
