import React, { useState, useEffect } from "react"
import { Link } from "gatsby"
import { useGlobalContext } from "../../context/context"
import { useForm } from "react-hook-form"
import { axiosStripePostRequest } from "../../API/API"
import { debounce, delayDebounceFn } from "../../utils/debouncer"
import {
  NAME_REGEX,
  NAME_SPACES_REGEX,
  EMAIL_REGEX,
  COMPANY_REGEX,
  DOMAIN_REGEX,
  DOMAIN_SPACES_REGEX,
} from "../../REGEX/REGEX"
import { setLocalStorage } from "../../localStorageService/localStorageService"
import FormFieldCheckbox from "../form/formFieldCheckbox"
import Loader from "../item/loader"
import "../../styles/components/form--sign-up.scss"
import "../../styles/components/form--global.scss"

const FormSignUp = () => {
  const {
    userInfo,
    setUserInfo,
    setIsLoading,
    setPackageError,
  } = useGlobalContext()
  const [domainLoading, setDomainLoading] = useState(false)
  const [domainAlreadyExsists, setDomainAlreadyExists] = useState(false)
  const [buttonDisabled, setButtonDisabled] = useState(false)

  const {
    register,
    handleSubmit,
    setValue,
    watch,
    formState: { errors },
    trigger,
    reset,
  } = useForm({
    defaultValues: {
      name: userInfo.name,
      email: userInfo.email,
      company: userInfo.company_name,
      domain: userInfo.domain,
    },
  })

  const watchAllFields = watch()

  useEffect(() => {
    if (!Object.values(watchAllFields).every(value => value)) {
      setButtonDisabled(true)
    } else {
      setButtonDisabled(false)
    }
  }, [watchAllFields])

  const formSubmit = (data, _) => {
    setUserInfo({ ...userInfo, ...data })

    const clientInfo = {
      name:data.name,
      email: data.email.toLowerCase(),
      company_name: data.company,
      domain: data.domain,
      features_package: userInfo.features_package,
    }

    if (!userInfo.features_package) {
      setPackageError(true)
      return
    }

    setLocalStorage("sideline-client-info", clientInfo)

    setIsLoading(true)

    axiosStripePostRequest({
      name:clientInfo.name,
      email: clientInfo.email,
      package: clientInfo.features_package,
      subdomain: clientInfo.domain,
      url: window.location.origin,
    })
      .then(res => {
        setLocalStorage("session_id", res.data.session_id)
        setLocalStorage("api_key", res.data.secret_key)
        window.location.href = res.data.url
        reset()
      })
      .catch(error => {
        console.error(error.message)
        setIsLoading(false)
        alert("Something went wrong, try again")
      })
  }

  const formatInput = () => {
    trigger("company")
    trigger("domain")

    const a =
      "àáâäæãåāăąçćčđďèéêëēėęěğǵḧîïíīįìıİłḿñńǹňôöòóœøōõőṕŕřßśšşșťțûüùúūǘůűųẃẍÿýžźż·/_,:;"
    const b =
      "aaaaaaaaaacccddeeeeeeeegghiiiiiiiilmnnnnoooooooooprrsssssttuuuuuuuuuwxyyzzz------"
    const p = new RegExp(a.split("").join("|"), "g")

    let formattedDomain = watchAllFields.company
      .toString()
      .toLowerCase()
      .replace(/\s+/g, "-") // Replace spaces with -
      .replace(p, c => b.charAt(a.indexOf(c))) // Replace special characters
      .replace(/&/g, "-and-") // Replace & with 'and'
      .replace(/[^\w\-]+/g, "") // Remove all non-word characters
      .replace(/\-\-+/g, "-") // Replace multiple - with single -
      .replace(/^-+/, "") // Trim - from start of text
      .replace(/-+$/, "") // Trim - from end of text

    setValue("domain", formattedDomain)

    setUserInfo({
      ...userInfo,
      domain: formattedDomain,
    })
  }

  useEffect(() => {
    debounce(
      "checkDomain",
      setDomainAlreadyExists,
      setDomainLoading,
      watchAllFields.domain,
      errors.domain,
      DOMAIN_REGEX,
      DOMAIN_SPACES_REGEX
    )
    return () => clearTimeout(delayDebounceFn)
  }, [watchAllFields.domain])

  return (
    <form
      action={"/"}
      className="form form--global form--sign-up"
      onSubmit={handleSubmit(formSubmit)}
    >
      <div className="form-item form-item-type--text form-item--full-name">
        <label htmlFor="input-full-name" className="required">
          Full Name
        </label>
        <input
          type="text"
          id="input-full-full-name"
          placeholder="John Doe"
          autoComplete="off"
          {...register("name", {
            required: "Full name is required",
            pattern: {
              value: NAME_REGEX,
              message:
                "Full name can not contain numbers or special characters",
            },
            validate: value => {
              return (
                [NAME_SPACES_REGEX].every(pattern => pattern.test(value)) ||
                "Full name must be capitalized and have at least one space"
              )
            },
          })}
          onKeyUp={() => {
            trigger("name")
          }}
        />
        {errors.name && (
          <small className="text--error">{errors.name.message}</small>
        )}
      </div>

      <div className="form-item form-item-type--email form-item--email">
        <label htmlFor="input-full-email" className="required">
          Email
        </label>
        <input
          type="text"
          id="input-full-email"
          placeholder="john@email.com"
          autoComplete="off"
          {...register("email", {
            required: "Email is required",
            pattern: {
              value: EMAIL_REGEX,
              message: "Invalid email address",
            },
          })}
          onKeyUp={() => {
            trigger("email")
          }}
        />

        {errors.email ? (
          <small className="text--error">{errors.email.message}</small>
        ) : null}
      </div>

      <div className="form-item form-item-type--text form-item--company">
        <label htmlFor="input-company" className="required">
          Company
        </label>
        <input
          type="text"
          id="input-full-company"
          placeholder="Your Company"
          autoComplete="off"
          {...register("company", {
            required: "Company name is required",
            minLength: {
              value: 2,
              message: "Name of the company must be at least 2 characters long",
            },
            pattern: {
              value: COMPANY_REGEX,
              message:
                "Company name can not or include special characters other than '&'",
            },
          })}
          onKeyUp={() => {
            formatInput()
          }}
        />
        {errors.company && (
          <small className="text--error">{errors.company.message}</small>
        )}
      </div>

      <div className="form-item form-item-type--domain form-item--domain">
        <label htmlFor="input-full-domain" className="required">
          Domain
        </label>
        <input
          type="text"
          id="input-full-domain"
          disabled={domainLoading}
          autoComplete="off"
          placeholder="your-domain"
          {...register("domain", {
            required: "Domain is required",
            minLength: {
              value: 3,
              message: "Domain must be at least 3 characters long",
            },
            pattern: {
              value: DOMAIN_REGEX,
              message: `Domain can only include lowercase letters, numbers and "-" character`,
            },
            validate: value => {
              return (
                [DOMAIN_SPACES_REGEX].every(pattern => pattern.test(value)) ||
                "Domain can not include spaces"
              )
            },
          })}
          onKeyUp={() => {
            trigger("domain")
          }}
        />
        {domainLoading && <Loader classes={"block-loader--input"} />}

        {errors.domain || domainAlreadyExsists ? (
          <small className="text--error">
            {errors.domain
              ? errors.domain.message
              : domainAlreadyExsists
              ? "Domain is already taken, try another one"
              : null}
          </small>
        ) : null}
      </div>

      <FormFieldCheckbox
        label="Sideline can drop feature announcements, cool info, and product offers my way by email or Facebook."
        name="agreement"
        value="agreement"
      />

      <div className="form--global form-actions">
        <button
          disabled={buttonDisabled}
          type="submit"
          className="button button--submit"
        >
          Start My Trial
        </button>

        <div className="description">
          Already have an account?
          <Link to="/login" className="text text--red">
            Log In
          </Link>
        </div>
      </div>
    </form>
  )
}

export default FormSignUp
