import React from "react"
import PropTypes from "prop-types"
import { useGlobalContext } from "../../context/context"

const FormFieldCheckbox = ({ label, value, name }) => {
  const { userInfo, setUserInfo } = useGlobalContext()

  const handleChange = () => {
    setUserInfo({ ...userInfo, newsletter: !userInfo.newsletter })
  }

  return (
    <div className={`form-item form-item-type--checkbox form-item--${name}`}>
      <input
        type="checkbox"
        id={`input-full-${name}`}
        name={name}
        value={value}
        checked={userInfo.newsletter}
        onChange={handleChange}
      />
      <label htmlFor={`input-full-${name}`}>{label}</label>
    </div>
  )
}

FormFieldCheckbox.propTypes = {
  label: PropTypes.string,
  checked: PropTypes.bool,
  value: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
}

export default FormFieldCheckbox
