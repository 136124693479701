import * as React from "react"
import { useGlobalContext } from "../../context/context"
import classNames from "classnames"

const PriceShort = ({
  children,
  special = false,
  priceType,
  priceSign,
  price,
  period,
  productID,
}) => {
  const { userInfo, setUserInfo, setPackageError } = useGlobalContext()

  const handleClick = () => {
    setUserInfo({
      ...userInfo,
      features_package: productID,
    })
    setPackageError(false)
  }

  return (
    <div
      onClick={handleClick}
      role={"button"}
      tabIndex={0}
      className={classNames(
        "price-option",
        {
          "price-option--saving": special,
        },
        { "price-option--selected": userInfo.features_package === productID }
      )}
    >
      {special ? (
        <div className="price-option__special">Save 20% Annually</div>
      ) : null}
      <div className="price-option__type">{priceType}</div>
      <div className="price-option__value">
        <span className="value">
          {priceSign}
          {price}
        </span>
        <span>{period}</span>
      </div>
      {children ? <div className="tooltip">{children}</div> : null}
    </div>
  )
}

export default PriceShort
