import { inputFieldCheck } from "./inputFieldCheck"

export let delayDebounceFn
export const debounce = (
  type,
  setAlreadyExists,
  setLoader,
  fieldValue,
  errors,
  FIRST_REGEX,
  SECOND_REGEX,
  setButtonDisable = undefined
) => {
  if (!fieldValue || errors) {
    setAlreadyExists(false)
  }

  delayDebounceFn = setTimeout(() => {
    if (
      fieldValue &&
      fieldValue.match(FIRST_REGEX) &&
      fieldValue.match(SECOND_REGEX)
    ) {
      inputFieldCheck(
        setLoader,
        setAlreadyExists,
        type,
        fieldValue,
        setButtonDisable
      )
    }
  }, 800)
}
