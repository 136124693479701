import React from "react"
import Layout from "../components/layout"
import Seo from "../components/seo"
import Container from "../components/block/container"
import FieldText from "../components/field/fieldText"
import PriceShort from "../components/item/priceShort"
import FormSignUp from "../components/full/formSignUp"
import SwitchButton from "../components/item/switchButton"
import Loader from "../components/item/loader"
import { useGlobalContext } from "../context/context"
import "../styles/components/page--form.scss"
import "../styles/components/block--form.scss"
import "../styles/components/block--sign-up-form.scss"
import "../styles/components/price-type-switch.scss"

const SignUpPage = () => {
  const { isLoading, packageError, subscriptionType } = useGlobalContext()

  return (
    <>
      <Layout showFooter={false}>
        <Seo
          title="Sign Up"
          description="15 free days of the best test drive ever."
          pageClass="page--form"
        />

        <Container
          classes="block--form block--sign-up-form"
          title="14 free days of the best test drive ever."
        >
          {isLoading && (
            <Loader
              loaderMsg="Redirecting to Stripe..."
              classes={"block-loader--form"}
            />
          )}
          <SwitchButton />
          <div className="price-options">
            <div>
              <PriceShort
                priceType="Basic"
                priceSign="$"
                productID={
                  subscriptionType === "monthly"
                    ? "prod_LULQG1tG1OUbtt"
                    : "prod_LajI3KDIdro1EB"
                }
                price={subscriptionType === "monthly" ? "49" : "588"}
                period={subscriptionType === "monthly" ? "/mo" : "/ye"}
              />
              <PriceShort
                priceType="Pro"
                priceSign="$"
                productID={
                  subscriptionType === "monthly"
                    ? "prod_LULQpgfnYLjXES"
                    : "prod_Laj85RID72pOt1"
                }
                price={subscriptionType === "monthly" ? "149" : "1430"}
                period={subscriptionType === "monthly" ? "/mo" : "/ye"}
                special={true}
              />
              <PriceShort
                priceType="Enterprise"
                priceSign="$"
                productID={
                  subscriptionType === "monthly"
                    ? "prod_LULRCOzMVTvWHw"
                    : "prod_Laj7RYoiXFnrJo"
                }
                price={subscriptionType === "monthly" ? "1220" : "14640"}
                period={subscriptionType === "monthly" ? "/mo" : "/ye"}
              />
            </div>
            {packageError && (
              <small className="text--error">
                You have to select the package
              </small>
            )}
          </div>
          <FormSignUp />
        </Container>

        <Container classes="block--disclaimer-form">
          <FieldText>
            <p>&copy; Sideline. All Rights Reserved.</p>
          </FieldText>
        </Container>
      </Layout>
    </>
  )
}

export default SignUpPage
